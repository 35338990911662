<template>
  <c-flex flex-dir="column">
    <Steps
      :current="current"
      :total="total"
    />
    <c-box mt="20px">
      <c-box mt="20px">
        <slot />
      </c-box>
    </c-box>
    <c-flex
      mt="30px"
      w="full"
      justify-content="space-between"
      gap="6"
    >
      <c-button
        :py="['14px', null]"
        :px="['24px', null]"
        rounded="full"
        variant-color="primary"
        :is-disabled="isPrev === false"
        @click="$emit('prev')"
      >
        <c-image
          :src="require('@/assets/icon-arrow-left-white.png')"
          w="24px"
          h="24px"
        />
        <c-text
          ml="10px"
          font-weight="700"
          :font-size="['12px', 'inherit']"
          :line-height="['18px', 'inherit']"
        >
          Kembali
        </c-text>
      </c-button>
      <c-button
        :py="['14px', null]"
        :px="['24px', null]"
        rounded="full"
        variant-color="primary"
        :is-disabled="isNext === false"
        @click="$emit('next')"
      >
        <c-text
          font-weight="700"
          :font-size="['12px', 'inherit']"
          :line-height="['18px', 'inherit']"
        >
          Selanjutnya
        </c-text>
        <c-image
          ml="10px"
          :src="require('@/assets/icon-arrow-right-white.png')"
          w="24px"
          h="24px"
        />
      </c-button>
    </c-flex>
  </c-flex>
</template>

<script>
import Steps from '@/components/steps.vue'
export default {
  name: 'CardStep',  
  components: { Steps },
  props: {
    current: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    isNext: Boolean,
    isPrev: Boolean,
  },
}
</script>
